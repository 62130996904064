import AIPT from './hr/fe_aipt.json'
import USER_MANAGEMENT from './hr/fe_user_management.json'
import INTEGRATIONS from './hr/fe_integrations.json'
import TRAININGS from './hr/fe_trainings.json'
import SECURITY_OBJECTIVES from './hr/fe_security_objectives.json'
import COMPLIANCE from './hr/fe_compliance.json'
import TRAINING_PROGRAM from './hr/fe_training_programs.json'
import ACCOUNT from './hr/account.json'
import ACCOUNT_SETTINGS from './hr/account_settings.json'
import APP from './hr/app.json'
import EXPERTA from './hr/experta.json'
import COMMON from './hr/fe_common.json'
import COMPANY_SETTINGS from './hr/company_settings.json'
import CONSULTANT from './hr/consultant.json'
import DISCUSSION from './hr/discussion.json'
import DOCUMENT_MANAGEMENT_SYSTEM from './hr/document_management_system.json'
import DOCUMENT_WIZARD from './hr/document_wizard.json'
import ERRORS from './hr/fe_errors.json'
import FORM from './hr/form.json'
import ISO_27001 from './hr/iso_27001.json'
import LANGUAGES from './hr/languages.json'
import MY_WORK from './hr/my_work.json'
import ONBOARDING from './hr/onboarding.json'
import ORGANIZATION from './hr/organization.json'
import PREONBOARDING from './hr/fe_preonboarding.json'
import PRICING from './hr/pricing.json'
import REGISTER_WIZARD from './hr/register_wizard.json'
import CORRECTIVE_ACTIONS from './hr/corrective_actions.json'
import INCIDENTS from './hr/incidents.json'
import INTERNAL_AUDITS from './hr/internal_audits.json'
import NONCONFORMITIES from './hr/nonconformities.json'
import REQUIREMENTS from './hr/fe_requirements.json'
import RISKS from './hr/fe_risks.json'
import SOA from './hr/soa.json'
import TRAINING_MODULE from './hr/training_module.json'
import REGISTERS_DATA from './hr/registers.json'
import REGISTERS_AND_MODULES from './hr/registers_and_modules.json'
import REPORTING from './hr/reporting.json'
import RESOURCE from './hr/resource.json'
import SETTINGS from './hr/settings.json'
import SLACK from './hr/slack.json'
import STEP_INPUTS from './hr/step_inputs.json'
import TASKS from './hr/fe_tasks.json'
import VALIDATION from './hr/fe_validation.json'
import REGULATION_DATA from './hr/regulation.json'
import CUSTOM_STEP from './hr/custom_step.json'
import SIMPLE_STEP from './hr/simple_step.json'
import STEP_0 from './hr/step0.json'
import STEP_1 from './hr/step1.json'
import STEP_2 from './hr/step2.json'
import STEP_3 from './hr/step3.json'
import STEP_4 from './hr/step4.json'
import STEP_5 from './hr/step5.json'
import STEP_6 from './hr/step6.json'
import STEP_7 from './hr/step7.json'
import STEP_8 from './hr/step8.json'
import STEP_9 from './hr/step9.json'
import STEP_10 from './hr/step10.json'
import STEP_11 from './hr/step11.json'
import STEP_12 from './hr/step12.json'
import STEP_13 from './hr/step13.json'
import STEP_14 from './hr/step14.json'
import STEP_15 from './hr/step15.json'
import STEP_16 from './hr/step16.json'
import STEP_17 from './hr/step17.json'
import STEP_18 from './hr/step18.json'
import STEP_19 from './hr/step19.json'
import STEP_20 from './hr/step20.json'
import STEP_21 from './hr/step21.json'
import STEP_22 from './hr/step22.json'
import STEP_23 from './hr/step23.json'
import STEP_24 from './hr/step24.json'
import STEP_25 from './hr/step25.json'
import STEP_26 from './hr/step26.json'
import STEP_27 from './hr/step27.json'
import STEP_28 from './hr/step28.json'
import STEP_29 from './hr/step29.json'
import STEP_30 from './hr/step30.json'
import STEP_31 from './hr/step31.json'
import STEP_32 from './hr/step32.json'
import STEP_33 from './hr/step33.json'
import STEP_34 from './hr/step34.json'
import STEP_37 from './hr/step37.json'
import STEP_39 from './hr/step39.json'
import STEP_40 from './hr/step40.json'
import STEP_42 from './hr/step42.json'
import STEP_66 from './hr/step66.json'
import STEP_77 from './hr/step77.json'
import STEP_88 from './hr/step88.json'
import STEP_99 from './hr/step99.json'

const REGULATION = {
    ...REGULATION_DATA,
    ...CUSTOM_STEP,
    ...SIMPLE_STEP,
    ...STEP_0,
    ...STEP_1,
    ...STEP_2,
    ...STEP_3,
    ...STEP_4,
    ...STEP_5,
    ...STEP_6,
    ...STEP_7,
    ...STEP_8,
    ...STEP_9,
    ...STEP_10,
    ...STEP_11,
    ...STEP_12,
    ...STEP_13,
    ...STEP_14,
    ...STEP_15,
    ...STEP_16,
    ...STEP_17,
    ...STEP_18,
    ...STEP_19,
    ...STEP_20,
    ...STEP_21,
    ...STEP_22,
    ...STEP_23,
    ...STEP_24,
    ...STEP_25,
    ...STEP_26,
    ...STEP_27,
    ...STEP_28,
    ...STEP_29,
    ...STEP_30,
    ...STEP_31,
    ...STEP_32,
    ...STEP_33,
    ...STEP_34,
    ...STEP_37,
    ...STEP_39,
    ...STEP_40,
    ...STEP_42,
    ...STEP_66,
    ...STEP_77,
    ...STEP_88,
    ...STEP_99
}

const REGISTERS = {
    CORRECTIVE_ACTIONS,
    INCIDENTS,
    INTERNAL_AUDITS,
    NONCONFORMITIES,
    RISKS,
    SOA,
    TRAINING_MODULE,
    ...REGISTERS_DATA
}

export const HR = {
    AIPT,
    TRAININGS,
    SECURITY_OBJECTIVES,
    REQUIREMENTS,
    COMPLIANCE,
    ACCOUNT,
    ACCOUNT_SETTINGS,
    APP,
    EXPERTA,
    COMMON,
    COMPANY_SETTINGS,
    CONSULTANT,
    DISCUSSION,
    DOCUMENT_MANAGEMENT_SYSTEM,
    DOCUMENT_WIZARD,
    ERRORS,
    FORM,
    ISO_27001,
    LANGUAGES,
    MY_WORK,
    ONBOARDING,
    ORGANIZATION,
    PREONBOARDING,
    PRICING,
    REGISTER_WIZARD,
    REGISTERS,
    REGISTERS_AND_MODULES,
    REGULATION,
    REPORTING,
    RESOURCE,
    SETTINGS,
    SLACK,
    STEP_INPUTS,
    TASKS,
    USER_MANAGEMENT,
    INTEGRATIONS,
    VALIDATION,
    TRAINING_PROGRAM
}
