const ROUTES_COMPANY_SETTINGS = {
    BASE: {
        name: 'company-settings'
    },
    USER_MANAGEMENT: {
        name: 'company-settings.user-management'
    },
    INTEGRATIONS: {
        name: 'company-settings.integrations'
    },
    INTEGRATIONS_SLACK: {
        name: 'company-settings.integrations.slack'
    },
    INTEGRATIONS_SHAREPOINT: {
        name: 'company-settings.integrations.sharepoint'
    },
    INTEGRATIONS_DROPBOX: {
        name: 'company-settings.integrations.dropbox'
    }
}

const NEED_HELP_URL =
    'https://help.advisera.com/hc/en-us/articles/10138996666652-User-Management-in-Conformio'

const LEARN_MORE_URL =
    'https://help.advisera.com/hc/en-us/articles/10138996666652-User-Management-in-Conformio'

const COMPANY_ROLES = {
    PROJECT_MANAGER: 'project_manager',
    MEMBER: 'member',
    BILLER: 'biller',
    AUDITOR: 'auditor'
}

const COMPANY_MEMBERS_STATUSES = {
    ACTIVE: 'active',
    INVITED: 'invited'
}

const COMPANY_MEMBERS_STATUSES_MAPPED = {
    [COMPANY_MEMBERS_STATUSES.ACTIVE]: {
        colorClass: 'color-success-alpha',
        backgroundColorClass: 'background-success-alpha',
        label: 'USER_MANAGEMENT.COMPANY_MEMBERS_STATUSES.ACTIVE',
        icon: 'icon-check-circle'
    },
    [COMPANY_MEMBERS_STATUSES.INVITED]: {
        colorClass: 'color-warning-alpha',
        backgroundColorClass: 'background-warning-alpha',
        label: 'USER_MANAGEMENT.COMPANY_MEMBERS_STATUSES.INVITED',
        icon: 'icon-refresh-circle'
    }
}

const INTEGRATIONS = {
    SLACK: 'slack',
    SHAREPOINT: 'sharepoint',
    DROPBOX: 'dropbox'
}

const INTEGRATIONS_STATUSES = {
    DISCONNECTED: 'disconnected',
    IDLE: 'idle',
    CONNECTED: 'connected'
}

export {
    ROUTES_COMPANY_SETTINGS,
    NEED_HELP_URL,
    LEARN_MORE_URL,
    COMPANY_ROLES,
    COMPANY_MEMBERS_STATUSES,
    COMPANY_MEMBERS_STATUSES_MAPPED,
    INTEGRATIONS,
    INTEGRATIONS_STATUSES
}
