import { COMPANY_SETTINGS_ROUTES, MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard, consultantsSubscriptionGuard } from './guards'

export default {
    name: COMPANY_SETTINGS_ROUTES.INDEX.name,
    path: 'company-settings',
    component: () => import('@/containers/CompanySettings/CompanySettings'),
    meta: {
        requiresAuth: true
    },
    children: [
        {
            name: COMPANY_SETTINGS_ROUTES.BASIC_INFORMATION.name,
            path: 'basic-information',
            component: () =>
                import('@/containers/CompanySettings/BasicInformation'),
            meta: {
                pageTitle: 'COMPANY_SETTINGS.BASIC_INFORMATION.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.BASIC_INFORMATION_ACCESS]
            }
        },
        {
            name: COMPANY_SETTINGS_ROUTES.PROJECT_SETTINGS.name,
            path: 'project-settings',
            component: () =>
                import('@/containers/CompanySettings/ProjectSettings'),
            meta: {
                pageTitle: 'COMPANY_SETTINGS.PROJECT_SETTINGS.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.PROJECT_SETTINGS_ACCESS]
            }
        },
        {
            name: COMPANY_SETTINGS_ROUTES.SECURITY.INDEX.name,
            path: 'security',
            component: () => import('@/containers/CompanySettings/Security'),
            meta: {
                pageTitle: 'COMPANY_SETTINGS.SECURITY.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.SECURITY_ACCESS]
            }
        },
        {
            name: COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX.name,
            path: 'subscription',
            component: () =>
                import('@/containers/CompanySettings/AppSubscription'),
            meta: {
                pageTitle: 'COMPANY_SETTINGS.SUBSCRIPTION.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard, consultantsSubscriptionGuard],
                permissions: [MY_PERMISSIONS.SUBSCRIPTIONS_ACCESS]
            }
        }
    ]
}
