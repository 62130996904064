import { MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { ROUTES_COMPANY_SETTINGS } from './constants'

export default {
    path: 'company-settings',
    name: ROUTES_COMPANY_SETTINGS.BASE.name,
    component: () => import('./ModuleCompanySettings'),
    props: true,
    redirect: to => ({
        name: ROUTES_COMPANY_SETTINGS.USER_MANAGEMENT.name,
        params: { ...to.params }
    }),
    children: [
        {
            path: 'user-management',
            name: ROUTES_COMPANY_SETTINGS.USER_MANAGEMENT.name,
            component: () => import('./user-management/UserManagement'),
            props: true,
            meta: {
                pageTitle: 'USER_MANAGEMENT.PAGE_TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.USER_MANAGEMENT_ACCESS]
            }
        },
        {
            path: 'integrations',
            name: ROUTES_COMPANY_SETTINGS.INTEGRATIONS.name,
            component: () => import('./integrations/CompanyIntegrations'),
            meta: {
                pageTitle: 'INTEGRATIONS.PAGE_TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.INTEGRATIONS_ACCESS]
            }
        },
        {
            path: 'integrations/slack',
            name: ROUTES_COMPANY_SETTINGS.INTEGRATIONS_SLACK.name,
            component: () => import('./integrations/IntegrationSlack'),
            props: true,
            meta: {
                pageTitle: 'INTEGRATIONS.SLACK.PAGE_TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.INTEGRATIONS_ACCESS]
            }
        },
        {
            path: 'integrations/sharepoint',
            name: ROUTES_COMPANY_SETTINGS.INTEGRATIONS_SHAREPOINT.name,
            component: () => import('./integrations/IntegrationSharepoint'),
            props: true,
            meta: {
                pageTitle: 'INTEGRATIONS.SHAREPOINT.PAGE_TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.INTEGRATIONS_ACCESS]
            }
        },
        {
            path: 'integrations/dropbox',
            name: ROUTES_COMPANY_SETTINGS.INTEGRATIONS_DROPBOX.name,
            component: () => import('./integrations/IntegrationDropbox'),
            props: true,
            meta: {
                pageTitle: 'INTEGRATIONS.DROPBOX.PAGE_TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.INTEGRATIONS_ACCESS]
            }
        }
    ]
}
