import AIPT from './en/fe_aipt.json'
import USER_MANAGEMENT from './en/fe_user_management.json'
import INTEGRATIONS from './en/fe_integrations.json'
import TRAININGS from './en/fe_trainings.json'
import SECURITY_OBJECTIVES from './en/fe_security_objectives.json'
import COMPLIANCE from './en/fe_compliance.json'
import TRAINING_PROGRAM from './en/fe_training_programs.json'
import ACCOUNT from './en/account.json'
import ACCOUNT_SETTINGS from './en/account_settings.json'
import APP from './en/app.json'
import EXPERTA from './en/experta.json'
import COMMON from './en/fe_common.json'
import COMPANY_SETTINGS from './en/company_settings.json'
import CONSULTANT from './en/consultant.json'
import DISCUSSION from './en/discussion.json'
import DOCUMENT_MANAGEMENT_SYSTEM from './en/document_management_system.json'
import DOCUMENT_WIZARD from './en/document_wizard.json'
import ERRORS from './en/fe_errors.json'
import FORM from './en/form.json'
import ISO_27001 from './en/iso_27001.json'
import LANGUAGES from './en/languages.json'
import MY_WORK from './en/my_work.json'
import ONBOARDING from './en/onboarding.json'
import ORGANIZATION from './en/organization.json'
import PREONBOARDING from './en/fe_preonboarding.json'
import PRICING from './en/pricing.json'
import REGISTER_WIZARD from './en/register_wizard.json'
import CORRECTIVE_ACTIONS from './en/corrective_actions.json'
import INCIDENTS from './en/incidents.json'
import INTERNAL_AUDITS from './en/internal_audits.json'
import NONCONFORMITIES from './en/nonconformities.json'
import REQUIREMENTS from './en/fe_requirements.json'
import RISKS from './en/fe_risks.json'
import SOA from './en/soa.json'
import TRAINING_MODULE from './en/training_module.json'
import REGISTERS_DATA from './en/registers.json'
import REGISTERS_AND_MODULES from './en/registers_and_modules.json'
import REPORTING from './en/reporting.json'
import RESOURCE from './en/resource.json'
import SETTINGS from './en/settings.json'
import SLACK from './en/slack.json'
import STEP_INPUTS from './en/step_inputs.json'
import TASKS from './en/fe_tasks.json'
import VALIDATION from './en/fe_validation.json'
import REGULATION_DATA from './en/regulation.json'
import CUSTOM_STEP from './en/custom_step.json'
import SIMPLE_STEP from './en/simple_step.json'
import STEP_0 from './en/step0.json'
import STEP_1 from './en/step1.json'
import STEP_2 from './en/step2.json'
import STEP_3 from './en/step3.json'
import STEP_4 from './en/step4.json'
import STEP_5 from './en/step5.json'
import STEP_6 from './en/step6.json'
import STEP_7 from './en/step7.json'
import STEP_8 from './en/step8.json'
import STEP_9 from './en/step9.json'
import STEP_10 from './en/step10.json'
import STEP_11 from './en/step11.json'
import STEP_12 from './en/step12.json'
import STEP_13 from './en/step13.json'
import STEP_14 from './en/step14.json'
import STEP_15 from './en/step15.json'
import STEP_16 from './en/step16.json'
import STEP_17 from './en/step17.json'
import STEP_18 from './en/step18.json'
import STEP_19 from './en/step19.json'
import STEP_20 from './en/step20.json'
import STEP_21 from './en/step21.json'
import STEP_22 from './en/step22.json'
import STEP_23 from './en/step23.json'
import STEP_24 from './en/step24.json'
import STEP_25 from './en/step25.json'
import STEP_26 from './en/step26.json'
import STEP_27 from './en/step27.json'
import STEP_28 from './en/step28.json'
import STEP_29 from './en/step29.json'
import STEP_30 from './en/step30.json'
import STEP_31 from './en/step31.json'
import STEP_32 from './en/step32.json'
import STEP_33 from './en/step33.json'
import STEP_34 from './en/step34.json'
import STEP_37 from './en/step37.json'
import STEP_39 from './en/step39.json'
import STEP_40 from './en/step40.json'
import STEP_42 from './en/step42.json'
import STEP_66 from './en/step66.json'
import STEP_77 from './en/step77.json'
import STEP_88 from './en/step88.json'
import STEP_99 from './en/step99.json'

const REGULATION = {
    ...REGULATION_DATA,
    ...CUSTOM_STEP,
    ...SIMPLE_STEP,
    ...STEP_0,
    ...STEP_1,
    ...STEP_2,
    ...STEP_3,
    ...STEP_4,
    ...STEP_5,
    ...STEP_6,
    ...STEP_7,
    ...STEP_8,
    ...STEP_9,
    ...STEP_10,
    ...STEP_11,
    ...STEP_12,
    ...STEP_13,
    ...STEP_14,
    ...STEP_15,
    ...STEP_16,
    ...STEP_17,
    ...STEP_18,
    ...STEP_19,
    ...STEP_20,
    ...STEP_21,
    ...STEP_22,
    ...STEP_23,
    ...STEP_24,
    ...STEP_25,
    ...STEP_26,
    ...STEP_27,
    ...STEP_28,
    ...STEP_29,
    ...STEP_30,
    ...STEP_31,
    ...STEP_32,
    ...STEP_33,
    ...STEP_34,
    ...STEP_37,
    ...STEP_39,
    ...STEP_40,
    ...STEP_42,
    ...STEP_66,
    ...STEP_77,
    ...STEP_88,
    ...STEP_99
}

const REGISTERS = {
    CORRECTIVE_ACTIONS,
    INCIDENTS,
    INTERNAL_AUDITS,
    NONCONFORMITIES,
    RISKS,
    SOA,
    TRAINING_MODULE,
    ...REGISTERS_DATA
}

export const EN = {
    AIPT,
    TRAININGS,
    SECURITY_OBJECTIVES,
    REQUIREMENTS,
    COMPLIANCE,
    ACCOUNT,
    ACCOUNT_SETTINGS,
    APP,
    EXPERTA,
    COMMON,
    COMPANY_SETTINGS,
    CONSULTANT,
    DISCUSSION,
    DOCUMENT_MANAGEMENT_SYSTEM,
    DOCUMENT_WIZARD,
    ERRORS,
    FORM,
    ISO_27001,
    LANGUAGES,
    MY_WORK,
    ONBOARDING,
    ORGANIZATION,
    PREONBOARDING,
    PRICING,
    REGISTER_WIZARD,
    REGISTERS,
    REGISTERS_AND_MODULES,
    REGULATION,
    REPORTING,
    RESOURCE,
    SETTINGS,
    SLACK,
    STEP_INPUTS,
    TASKS,
    USER_MANAGEMENT,
    INTEGRATIONS,
    VALIDATION,
    TRAINING_PROGRAM
}
