import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { camelCase, has, includes } from 'lodash-es'
import { account } from './modules/account'
import { company } from './modules/company'
import { register } from './modules/register'
import { regulation } from './modules/regulation'
import { document } from './modules/document'
import { task } from './modules/task'
import { dms } from './modules/dms'
import { errors } from './modules/errors'
import { loading } from './modules/loading'
import { general } from './modules/general'
import { risks } from './modules/risks'
import { comment } from './modules/comment'
import { soa } from './modules/soa'
import { incident } from './modules/incident'
import { nonconformity } from './modules/nonconformity'
import { correctiveAction } from './modules/corrective-action'
import { internalAudit } from './modules/internal-audit'
import { reporting } from './modules/reporting'
import { managementReview } from './modules/management-review'
import { managementReviewSession } from './modules/management-review-session'
import { myWork } from './modules/my-work'
import { slack } from './modules/slack'
// import { integration } from './modules/integration'
import { notification } from './modules/notification'
import { layeredModals } from './modules/layeredModals'
import { support } from './modules/support'
import { router } from './modules/router'
import { experta } from './modules/experta'
import { store as implementationSteps } from '@src/modules/compliance/implementation-steps/store.js'
import { store as trainings } from '@src/modules/registers/trainings/store.js'
import { store as securityObjectives } from '@src/modules/registers/security-objectives/store.js'
import { store as requirements } from '@src/modules/registers/requirements/store.js'
import { COOKIE_LOCALSTORAGE } from '@/constants/storage'
import {
    getLocalStorageItem,
    removeLocalStorageItem
} from '../services/localStorage'
import { confirmModal } from './modules/confirm-modal'

const cookieName = 'conformio'

// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookies.get(cookieName) || uuidv4()

// Store the encryption token in a secure cookie.
Cookies.set(cookieName, encryptionToken, { secure: true, expires: 180 })

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
    storage: {
        getItem (key) {
            if (COOKIE_LOCALSTORAGE) {
                return Cookies.get(key)
            } else {
                return window.localStorage.getItem(key)
            }
        },
        setItem (key, value) {
            if (COOKIE_LOCALSTORAGE) {
                return Cookies.set(key, value, { expires: 3, secure: true })
            } else {
                return window.localStorage.setItem(key, value)
            }
        },
        removeItem (key) {
            if (COOKIE_LOCALSTORAGE) {
                Cookies.remove(key)
            } else {
                window.localStorage.removeItem(key)
            }
        }
    },
    reducer: state => ({
        company: state.company,
        account: state.account,
        router: state.router
    })
})

const state = {
    appLoading: false,
    isAutosaving: false
}

const mutations = {
    SET_IS_AUTOSAVING (state, payload) {
        state.isAutosaving = payload
    },

    SET_APP_LOADING (state, payload) {
        state.appLoading = payload && !state.isAutosaving
    }
}

const actions = {
    clearAll ({ commit }, options = {}) {
        const { skipAccount = false } = options

        !skipAccount && commit('account/RESET_ACCOUNT')
        commit('company/RESET_COMPANY')
        commit('errors/RESET_ERRORS')
        commit('regulation/RESET_REGULATION')
        commit('document/RESET_DOCUMENT')
        commit('myWork/RESET_MY_WORK')
        commit('risks/RESET_RISKS')
        commit('loading/RESET_LOADING')
    }
}

const getters = {
    appLoading: state => state.appLoading
}

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        implementationSteps,
        account,
        company,
        register,
        regulation,
        document,
        task,
        dms,
        errors,
        loading,
        general,
        risks,
        comment,
        soa,
        incident,
        nonconformity,
        correctiveAction,
        internalAudit,
        reporting,
        managementReview,
        managementReviewSession,
        securityObjectives,
        requirements,
        myWork,
        trainings,
        slack,
        // integration,
        notification,
        layeredModals,
        confirmModal,
        support,
        router,
        experta
    },
    plugins: [vuexLocal.plugin]
})

store.subscribeAction(action => {
    const [, name = 'default'] = action.type.split('/')
    if (name.substring(0, 3) === 'get') {
        store.commit('loading/SET_RESOURCE_LOADING', {
            type: `${name.charAt(3).toLowerCase()}${name.slice(4)}Loading`,
            status: true
        })
    }
})

store.subscribe((mutation, state) => {
    const [module, name = 'default'] = mutation.type.split('/')
    if (name.substring(0, 3) === 'SET' && !includes(name, 'LOADING')) {
        const resourceTypeLoading = `${camelCase(name.slice(4))}Loading`
        const resourceTypeFetched = `${camelCase(name.slice(4))}Fetched`

        if (state.loading[resourceTypeLoading]) {
            store.commit('loading/SET_RESOURCE_LOADING', {
                type: resourceTypeLoading,
                status: false
            })
        }

        if (has(state.loading, resourceTypeFetched)) {
            store.commit('loading/SET_RESOURCE_FETCHED', {
                type: resourceTypeFetched,
                status: true
            })
        }
    } else if (includes(name, 'RESET')) {
        store.commit('loading/SET_RESOURCE_LOADING', {
            type: `${module.toLowerCase()}Loading`,
            status: true
        })

        store.commit('loading/SET_RESOURCE_FETCHED', {
            type: `${module.toLowerCase()}Fetched`,
            status: false
        })
    }
})

export default store
